
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.mat-mdc-select > .mat-mdc-select-trigger {
  border: none;
}

.mat-mdc-text-field-wrapper{
  outline: none !important;
  border: none!important;
  
}

.mat-mdc-select-panel{
  // background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important

}

.mat-mdc-option:hover{
  background: whitesmoke !important;
}
mat-select {
  // background-color: white;
  border : 2px solid #921515;

}

.mat-mdc-select{
  outline: none !important;
  border: none !important;
  border-radius: 10px;
} 

// table customization

thead{
  background: rgb(237 233 254 ) !important;
}

.mat-mdc-option .mdc-list-item {
  background-color: violet !important;
}

tr{
  height: 35px !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#email > div > div:nth-child(1) > div > input{
  height:auto !important;
}  

input:not([type="checkbox"]),
button {
  height: 33px !important;
}

table tr td span{
  vertical-align: middle !important;
}

.drawer_header{
  background: #d9dafd !important;
}

.mdc-list-item__primary-text
{
  font-size: 12px !important;
  font-family: sans-serif;
}

